import { I18nValue, getLangCode } from '../i18n/lang-context'

export type Author = {
  teamOrder: number
  slug: string
  name: string
  title: I18nValue
  email: string
  phone: string
  linkedInUserId: string
  linkedInShowcaseId: string
  linkedInCompanyId: string
  twitterId: string
  instagramId: string
  facebookId: string
  youtubeId: string
  bio: I18nValue
  photo: {
    url: string
  }
  post: any[]
}

export function toAuthor(_author: any,  lang?: string) {
  lang = lang || getLangCode()
  const author = {..._author}
  const title = author.title
  author.title = {}
  author.title[lang] = title
  const bio = author.bio
  author.bio = {}
  author.bio[lang] = bio
  return author
}
export function toAuthors(_authors: any[],  lang?: string) {
  lang = lang || getLangCode()
  const results: Author[] =[]
  for(let _author of _authors) {
    results.push(toAuthor(_author, lang))
  }
  return results
}