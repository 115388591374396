'use client'

import Image from 'next/image'
import { toAuthor } from '../jbda/author'
import { useAuthorDialog } from '../jbda/jbda/jbda-author'

export default function PostAuthor({ post, className = '' }: { post: any, className?: string }) {
  
  const { openAuthorDialog } = useAuthorDialog()

  // Rendering
  //--------------------------------------------------------------------------------------------------------------------
  return (
    <div id='AUTHOR' className={'flex w-fit items-center cursor-help ' + className} onClick={(e) => {
      // Tells the user agent that if the event does not get explicitly handled, its default action should not be taken as it normally would be
      e.preventDefault()
      // Prevents further propagation of the current event in the capturing and bubbling phases
      e.stopPropagation()
      openAuthorDialog(toAuthor(post.author))
    }}>
      <div className={'mr-2 h-6 w-6 shrink-0'}>
        {post.author?.photo ? (
          <Image id='AUTHOR_IMAGE' src={post.author?.photo.url} alt={post.author?.name}
            className={'overflow-hidden rounded-full'}
            width={0}
            height={0}
            sizes='100vw'
            priority={true}
            style={{
              height: 24,
              width: 24
            }} />
        ) : (<></>
        )}
      </div>
      <div id='AUTHOR_NAME'>{post.author.name}</div>
    </div>
    
  )
}
