'use client'

import { useDialogActions } from '@/components/dialog'
import { getLangCode, getLocalizedUri, I18nDictionary, useI18n } from '@/components/i18n/lang-context'
import MD from '@/components/markdown'
import Reveal from '@/components/reveal'
import { Card } from '@/components/ui/card'
import { Flex, FlexCol } from '@/components/ui/flex'
import { siteConfig } from '@/config/site.config'
import { buildEmailUrl, buildPhoneUrl, crop } from '@/lib/utils/utils'
import Image from 'next/image'
import { MouseEvent } from 'react'
import { FaCopy, FaEnvelopeSquare, FaFacebookSquare, FaInstagramSquare, FaLinkedin, FaPhoneSquare, FaShareAlt, FaTwitterSquare, FaYoutubeSquare } from 'react-icons/fa'
import { Author } from '../author'
import { useCookiesConsentDialog } from '../cookies-dialog'


const I18N: I18nDictionary = {
  copy: {en: 'Copy', fr: 'Copier'},
  share: {en: 'Share', fr: 'Partager'},
  linkTo: {en: 'link to', fr: 'lien vers'},
  of: {en: 'of', fr: 'de'},
  the: {en: '', fr: 'le '},
  profile: {en: 'profile', fr: 'profil'},
  email: {en: 'email', fr: 'mail'},
  phone: {en: 'phone number', fr: 'numéro de téléphone'}
}

export function useAuthorDialog() {
  const { openDialog } = useDialogActions()
  const { openCookiesConsentDialog } = useCookiesConsentDialog()

  const _openCookiesConsentDialog = async () => {
    openCookiesConsentDialog('welcome', true)
  }
  const openAuthorDialog = (author: Author) => {
    openDialog({ content: <AuthorDialogContent author={author}/>, onCloseAfter: _openCookiesConsentDialog})
  }

  return { openAuthorDialog }
}

export function AuthorDialogContent({author}: {author: Author}) {
  const i18n = useI18n(I18N)
  const aboutUsUrl = siteConfig.links.site + getLocalizedUri('/about-us?member='+author.slug)

  const copy = (event: MouseEvent, content: string) => {
    event.stopPropagation()
    event.preventDefault()
    navigator.clipboard.writeText(content)
  }
  const share = (event: MouseEvent, data: string, title: string) => {
    event.stopPropagation()
    event.preventDefault()
    title = title.substring(0, 1).toUpperCase() + title.substring(1)
    navigator.share({
      title: data.startsWith('http') ? title : undefined,
      url: data.startsWith('http') ? data : undefined,
      text: !data.startsWith('http') ? title + ' → ' + data : undefined
    })
  }
  const getEmailUrl = (author: Author) => {
    return author.email && buildEmailUrl(author.email)
  }
  const getPhoneUrl = (author: Author) => {
    return author.phone && buildPhoneUrl(author.phone)
  }
  const getLinkedInUserUrl = (author: Author) => {
    return author.linkedInUserId && 'https://www.linkedin.com/in/' + author.linkedInUserId + '/'
  }
  const getLinkedInShowcaseUrl = (author: Author) => {
    return author.linkedInShowcaseId && 'https://www.linkedin.com/showcase/' + author.linkedInShowcaseId + '/'
  }
  const getLinkedInCompanyUrl = (author: Author) => {
    return author.linkedInCompanyId && 'https://www.linkedin.com/company/' + author.linkedInCompanyId + '/'
  }
  const getTwitterUrl = (author: Author) => {
    return author.twitterId && 'https://x.com/' + author.twitterId + '/'
  }
  const getInstagramUrl = (author: Author) => {
    return author.instagramId && 'https://www.instagram.com/' + author.instagramId + '/'
  }
  const getFacebookUrl = (author: Author) => {
    return author.facebookId && 'https://www.facebook.com/' + author.facebookId + '/'
  }
  const getYoutubeUrl = (author: Author) => {
    return author.youtubeId && 'https://www.youtube.com/@' + author.youtubeId + '/'
  }

  const getProfileEntry = (link: string, value: string, text: string, name: string, type: string, icon: JSX.Element) => {
    const title = type + ' ' + i18n.of + ' ' + name
    return link && 
    <Flex className='justify-center flex-row items-center mt-4'>
      <div className='w-4 opacity-70 sm:ml-24 ml-5'>
        {icon}
      </div>
      <MD textAlign='center' className='font-medium sm:text-lg grow' target='_blank'>
        {'['+(!text ? type : text)+']('+link+')'}
      </MD>
      <div id='EMAIL_COPY' className={'h-4 w-4  opacity-50 hover:opacity-100 cursor-pointer'}
        title={i18n.copy + ' ' + title}
        onClick={(event) => copy(event, value)}>
        <FaCopy/>
      </div>
      <div id='EMAIL_SHARE' className={'h-4 w-4 ml-2 opacity-50 hover:opacity-100 cursor-pointer sm:mr-24 mr-5'}
        title={i18n.share + ' ' + title}
        onClick={(event) => share(event, value, title)}>
        <FaShareAlt/>
      </div>
    </Flex>
  }
  
  return (
  
    <Card variant='consent' className='w-full p-1 sm:w-fit sm:min-w-[640px] sm:max-w-[640px] sm:p-4' style={{ margin: '20px' }}>
      <div id='PROFILE_ACTIONS' className={'flex sm:flex-row flex-col m-4 absolute top-4 right-2 text-main-dark sm:text-main-lightest'}>
        <div id='PROFILE_URL_COPY' className={'h-4 w-4 mt-4 ml-4 opacity-50 hover:opacity-100 cursor-pointer'}
          title={i18n.copy + ' ' + i18n.linkTo + ' ' + i18n.the + i18n.profile + ' ' + i18n.of + ' ' + author.name}
          onClick={(event) => copy(event, aboutUsUrl)}>
          <FaCopy/>
        </div>
        <div id='PROFILE_URL_SHARE' className={'h-4 w-4 mt-4 ml-4 opacity-50 hover:opacity-100 cursor-pointer'}
          title={i18n.share + ' ' + i18n.linkTo + ' ' + i18n.the + i18n.profile + ' ' + i18n.of + ' ' + author.name}
          onClick={(event) => share(event, aboutUsUrl, siteConfig.appName + ' - ' + i18n.profile + ' ' + i18n.of + ' ' + author.name)}>
          <FaShareAlt/>
        </div>
      </div>

      <Flex className='justify-center gap-8 lg:gap-16'>
        <FlexCol className='flex-col items-center'>
          <MD className='font-medium sm:text-lg'>{'***'+author.name+'***'}</MD>
          <MD className='font-medium opacity-70'>{'**'+author.title[getLangCode()]+'**'}</MD>
        </FlexCol>
      </Flex>
      <Flex className='justify-center p-5'>
        <Reveal id='HEAD_SEPARATOR' delay={100} translateX={100} duration={350} key={author.name}
          className='relative flex w-1/3 flex-col items-center gap-4 sm:w-1/4 lg:w-1/3 lg:gap-8 aspect-square  overflow-hidden rounded-full shadow-sm transition-all hover:shadow-md'>
          <Image src={author.photo.url} alt={author.name} className='transition-all hover:scale-105'
            width={0}
            height={0}
            sizes='250px'
            style={{ objectFit: 'cover' }}
            fill/>
        </Reveal>
      </Flex>

      {getProfileEntry(getEmailUrl(author), author.email, author.email, author.name, i18n.email, <FaEnvelopeSquare className='w-6 h-6'/>)}
      {getProfileEntry(getPhoneUrl(author), author.phone, author.phone, author.name, i18n.phone, <FaPhoneSquare className='w-6 h-6'/>)}
      {getProfileEntry(getLinkedInUserUrl(author), author.linkedInUserId, author.teamOrder < 0 ? '' : crop(author.linkedInUserId, 20), author.name, 'LinkedIn', <FaLinkedin className='w-6 h-6'/>)}
      {getProfileEntry(getLinkedInShowcaseUrl(author), author.linkedInShowcaseId, author.teamOrder < 0 ? '' : crop(author.linkedInShowcaseId, 20), author.name, 'LinkedIn', <FaLinkedin className='w-6 h-6'/>)}
      {getProfileEntry(getLinkedInCompanyUrl(author), author.linkedInCompanyId, author.teamOrder < 0 ? '' : crop(author.linkedInCompanyId, 20), author.name, 'LinkedIn', <FaLinkedin className='w-6 h-6'/>)}
      {getProfileEntry(getTwitterUrl(author), author.twitterId, author.teamOrder < 0 ? '' : crop('@'+author.twitterId, 20), author.name, 'Twitter/X', <FaTwitterSquare className='w-6 h-6'/>)}
      {getProfileEntry(getInstagramUrl(author), author.instagramId, author.teamOrder < 0 ? '' : crop(author.instagramId, 20), author.name, 'Instagram', <FaInstagramSquare className='w-6 h-6'/>)}
      {getProfileEntry(getFacebookUrl(author), author.facebookId, author.teamOrder < 0 ? '' : crop(author.facebookId, 20), author.name, 'Facebook', <FaFacebookSquare className='w-6 h-6'/>)}
      {getProfileEntry(getYoutubeUrl(author), author.youtubeId, author.teamOrder < 0 ? '' : crop('@'+author.youtubeId, 20), author.name, 'Youtube', <FaYoutubeSquare className='w-6 h-6'/>)}

      {/* <CardContent className='w-full grow !py-2'>
        {author.bio[getLangCode()] &&
          <FlexCol>
            <div id='QUOTE_OPEN' className='opacity-70'><RiDoubleQuotesL/></div>
            <MD>{author.bio[getLangCode()]}</MD>
            <div id='QUOTE_CLOSE' className='opacity-70'><RiDoubleQuotesR/></div>
          </FlexCol>
        }
        {author.post && author.post.length > 0 && <FlexCol><MD className='font-medium sm:text-lg'>{'**Lastest Posts**'}</MD></FlexCol>}
        {author.post?.map((post: any) =>
          <FlexCol>
            <PostCard post={post} query='' horizontal={true}/>
          </FlexCol>)}
      </CardContent> */}
    </Card>
  )
}