'use client'

import { format } from 'date-fns'
import { enUS, fr } from 'date-fns/locale'
import { useTheme } from 'next-themes'
import Image from 'next/image'
import Link from 'next/link'
import { getLangCode, getLocalizedUri } from '../i18n/lang-context'
import MD from '../markdown'
import Tooltip from '../ui/tooltip'
import { useScreen } from '../utils/windowSize'
import PostAuthor from './post-author'

const getHighlightedText = (text: string, _query: string) => {
  const query = _query.trim().toLowerCase()
  if (!query || query.length === 0 || !text.toLowerCase().includes(query))
    return text
  const regex = new RegExp(`(${query})`, 'gi')
  const parts = text.split(regex)
  const result = parts.map((part, i) => regex.test(part) ? '```' + part + '```' : part).join('')
  return result
}

/**
 * Post Component
 * ---------------------------------------------------------------------------------------------------------------------
 */
export default function PostCard({ post, query, className = '', horizontal }: { post: any, query: string, className?: string, horizontal?: boolean }) {

  const screen = useScreen()
  const {theme} = useTheme()

  const formatDate = (date: any) => format(new Date(date), 'P', { locale: getLangCode() === 'en' ? enUS : fr })
  const categories = post.categories.map((category: any) => '#'+category.name).sort()
  // Rendering
  //--------------------------------------------------------------------------------------------------------------------
  return (
    <Link id='POST_CARD'
      href={getLocalizedUri('/blog/' + post.slug)}
      className={'group max-w-full flex flex-' + (horizontal ? 'row' : 'col') + ' bg-main-lightest rounded-none sm:rounded-lg overflow-hidden shrink-0 cursor-pointer ' +
        'shadow-sm hover:shadow-md transition-all ' + className}>

      {!horizontal &&
        <div id='IMAGE_TITLE' className={'relative h-60 w-full sm:h-[20rem] lg:h-[15rem]'}>
          <div id='IMAGE' className={'relative h-full w-full'}>
            {post.featuredImage ?
              (<Image src={post.featuredImage.url} alt={post.featuredImage.alt||''}
                className={'object-cover'} fill width={0} height={0} sizes='50vw' />) :
              (<></>)}
          </div>
          {categories.length > 0 &&
            <div id='CATEGORY_BAND'
              className={'absolute top-3 right-2 flex flex-col justify-between items-right p-1 rounded-lg cursor-help' +
                ' text-[10px] sm:text-[14px] font-[200] bg-black/50 text-main-lightest shadow-xs block text-right text-white'}
              onClick={(e) => {
                // Tells the user agent that if the event does not get explicitly handled, its default action should not be taken as it normally would be
                e.preventDefault()
                // Prevents further propagation of the current event in the capturing and bubbling phases
                e.stopPropagation()
              }}>
              <Tooltip content={categories.join(' | ')}>
                <MD textAlign='right'>{'&nbsp;&nbsp;?&nbsp;&nbsp;'}</MD>
              </Tooltip>
            </div>
          }
          <div id='TITLE_BAND'
            className={'absolute bottom-0 left-0 w-full flex flex-col justify-between items-center p-4 ' +
              'text-[22px] sm:text-[26px] font-[400] bg-black/50 text-main-lightest shadow-md block text-center text-white'}>
            <MD textAlign='center'>{getHighlightedText(post.title, query)}</MD>
          </div>
        </div>
      }
      <div id='CONTENT' className={'flex w-full flex-' + (horizontal && screen.sm ? 'row' : 'col') + ' bg-white/50 dark:bg-black/50'}>
        {horizontal &&
          <div id='IMAGE' className={'relative ' + (!screen.sm ? 'h-60' : 'h-full') + ' w-full'}>
            {post.featuredImage ?
              (<Image src={post.featuredImage?.url} alt={post.featuredImage.alt||''}
                className={'object-cover'} fill={true} width={0} height={0} sizes='50vw'/>) :
              (<></>)}
          </div>
        }
        {!horizontal &&
          <div id='INFO_BAND'
            className={'flex w-full items-center justify-between p-4 pb-2 pt-3 text-[14px] font-[300] shadow-sm transition-all'}>
            {/* {categories.length > 0 &&
              <div id='POST_CATEGORIES' className={'flex gap-2'} onClick={(e) => {
                // Tells the user agent that if the event does not get explicitly handled, its default action should not be taken as it normally would be
                e.preventDefault()
                // Prevents further propagation of the current event in the capturing and bubbling phases
                e.stopPropagation()
              }}>
                <Tooltip content={categories.join(' | ')}>
                  <div id='POST_CATEGORY' className={'mr-2 cursor-help border-b-[2px] border-dotted border-slate-500/50 transition-all hover:border-primaryBright'}>
                    {categories[0].substring(0, categories[0].indexOf(' ') < 0 ? categories[0].length :categories[0].indexOf(' ')) + (categories.length === 1 ? '' : ' ...')}
                  </div>
                </Tooltip>
              </div>
            } */}
            <div className={'grow'} />
            <PostAuthor className={theme === 'dark' ? 'text-white' : 'text-black'} post={post}/>
            &nbsp;|&nbsp;
            <div id='LAST_UPDATE' className={'flex w-fit items-center'}>
              <div id='LAST_UPDATE_DATE' className={theme === 'dark' ? 'text-white' : 'text-black'}>
                {formatDate(post.updatedAt)}
              </div>
            </div>
            {formatDate(post.createdAt) !== formatDate(post.updatedAt) &&
              <>&nbsp;&nbsp;
                <div id='CREATE' className={'flex w-fit items-center'}>
                  <div id='CREATE_DATE' className={'text-gray-400'}>
                    {' (' + formatDate(post.createdAt) + ')'}
                  </div>
                </div>
              </>
            }
          </div>
        }
        <div id='EXCERPT' className={'p-6 text-[16px] font-[300] sm:text-[18px] w-full'}>
          {horizontal ?
            <MD>{getHighlightedText(post.metaDescription, query)}</MD> :
            <MD>{getHighlightedText(post.excerpt, query)}</MD>
          }
        </div>
      </div>
    </Link>
  )
}
